<template>
  <Layout>
    <div class="container-fluid pt-5" style="margin-bottom: 120px">
      <companyName :name="this.company.company.name"/>
      <div class="title mb-4">
        Documents
      </div>
      <btn-back/>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <BootStrapAlert/>
        </div>
        <div class="col-md-12 col-lg-12">

          <div class="document"
               @click="this.$router.push({
                    name: 'grosssales',
                    params: {
                      id: this.id
                    }
                 })">
            <div id="drop-area" class="d-none">
              <input class="form-control" name="files[]" type="file" multiple />
            </div>
            <span class="dot"></span>
            <div class="title">
              Quarterly Gross Sales
            </div>
          </div>

          <div class="document"
               @click="this.$router.push({
                    name: 'healthexpenses',
                    params: {
                      id: this.id
                    }
                 })"
          >
            <span class="dot"></span>
            <div class="title">
              Cobra and Health Expenses
            </div>
          </div>

          <div class="document"
               @click="this.$router.push({
                    name: 'payroll',
                    params: {
                      id: this.id
                    }
                 })"
          >
            <span class="dot"></span>
            <div class="title">
              Payroll
            </div>
          </div>

          <div class="document"
               @click="this.$router.push({
                    name: 'uploadtaxdocument',
                    params: {
                      id: this.id
                    }
                 })">
            <span class="dot"></span>
            <div class="title">
              Upload Tax Document
            </div>
          </div>

          <div class="document" @click="this.$router.push({
                    name: 'ownersfamilymembers',
                    params: {
                      id: this.id
                    }
                 })">
            <span class="dot"></span>
            <div class="title">
              Owners and Family Members
            </div>
          </div>

<!--          <div class="document" @click="this.$router.push({-->
<!--                    name: 'irsform2848',-->
<!--                    params: {-->
<!--                      id: this.id-->
<!--                    }-->
<!--                 })">-->
<!--            <span class="dot"></span>-->
<!--            <div class="title">-->
<!--              IRS Form - 2848-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import BootStrapAlert from "@/components/utils/Alert";
import btnBack from "@/components/btn-back/btnBack";
import companyName from "@/components/company-name/company-name";
import $ from 'jquery';
import {companyStore} from '@/store/company';


export default {
  props: ['id'],
  name: "documents",
  setup(){
    const company = companyStore();
    return {
      company,
    }
  },
  components: {
    Layout,
    BootStrapAlert,
    btnBack,
    companyName
  },
  data(){
    return{}
  },
  methods: {
    dropFiles(e){
      e.preventDefault();
      // let dt = e.dataTransfer
      // let files = dt.files
      // let fd = new FormData()
    },
    uploadFile(){
      $('#drop-area input')[0].click();
    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss" src="./documents.scss"></style>